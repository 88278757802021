<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}

.c-main {
  overflow: hidden;
}

.btn__modal {
  background: transparent;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid;
}
.btn__modal:hover {
  background: #000;
  color: #fff;
}
.btn-el-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  border: 2px solid #0016b0;
}
.btn-el-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.btn__actualizar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  font-weight: 500 !important;
  font-size: 1em !important;
  letter-spacing: 1px;
}
.avatar__alicia-pq {
  height: 55vh;
  margin-top: 20vh;
}

.container-alicia-pq {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5vh;
}

.pq {
  width: 17em;
  height: 15em;
  background: #4b92fc;
  border-radius: 100%;
  padding: 36px 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;

  span {
    font-size: 2em;
    font-weight: bold;
  }
  p {
    color: #fff;
  }
}

.pq .triangulo-pq {
  width: 0;
  height: 0;
  border-left: 48px solid #4b92fc;
  border-top: 12px solid transparent;
  border-bottom: 47px solid transparent;
  position: relative;
  top: -5vh;
  left: 11vw;
  -webkit-transform: rotate(49deg);
  transform: rotate(49deg);
  z-index: -1;
}

.card {
  margin-bottom: 0px !important;
}

.text-content-modal {
  font-size: 1em;
}

@media only screen and (max-width: 575px) {
  .avatar__alicia-pq {
    height: 45vh;
    margin-top: 13vh;
  }

  .title__section {
    font-size: 0.8em;
  }

  .pq {
    width: 12em;
    height: 11em;
    padding: 1.2em 2em;
  }

  .pq p {
    font-size: 0.8em;
  }

  .pq span {
    font-size: 1.2em;
  }

  .pq .triangulo-pq {
    top: -3vh;
    left: 26vw;
  }

  .modal.show .modal-dialog {
    max-width: 80% !important;
  }

  .text-content-modal {
    font-size: 0.8em;
    text-align: justify;
  }

  .btn__modal {
    padding: 4px 8px;
    font-size: 0.8em;
  }
}
</style>

<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    ></loading>
    <div class="col-12 col-md-7">
      <CRow>
        <CCol col>
          <CCard>
            <CCardHeader>
              <strong>Actualización documento identidad</strong>
            </CCardHeader>
            <CCardBody>
              <div class="form-group col-md-10">
                <label for="">Seleccione una suscripción</label>
                <select
                  class="form-control"
                  @change="changeSuscription($event)"
                >
                  <option value="">--Seleccionar--</option>
                  <option
                    v-for="(item, index) in suscripciones"
                    :value="index + 1"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>

              <CCard>
                <CCardBody style="padding-left: 0 !important">
                  <div v-if="state == 1">
                    <div class="form-group col-md-10">
                      <label for="">Tipo de suscripción</label>
                      <p v-if="categoria == 'print'">
                        Impresa {{ periodoSuscripcion }}
                      </p>
                      <p v-else-if="categoria == 'bundle'">
                        Impresa & digital {{ periodoSuscripcion }}
                      </p>
                      <p v-else>Digital {{ periodoSuscripcion }}</p>
                    </div>
                    <div class="form-group col-md-10">
                      <label for="">Dias de entrega </label>
                      <p v-if="diasEntrega == ''">No tiene dias de entrega</p>
                      <p v-else>{{ diasEntrega }}</p>
                    </div>
                    <div class="form-group col-md-10">
                      <label for="">Siguiente fecha de pago </label>
                      <p v-if="siguientePago == ''">
                        No tiene una fecha siguiente de pago
                      </p>
                      <p v-else>{{ siguientePago }}</p>
                    </div>

                    <div class="form-group col-md-10">
                      <button
                        type="button"
                        class="btn-el-comercio btn__actualizar"
                        @click="$bvModal.show('modal-lg') + setValModal()"
                      >
                        Actualizar Dni
                      </button>
                    </div>
                  </div>
                  <div v-else-if="state == 0 || state == ''">
                    <p style="color: green">
                      {{ firstName }}, selecciona una suscripción.
                    </p>
                  </div>
                </CCardBody>
              </CCard>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>

    <div class="col-12 col-md-5 d-flex align-items-center justify-content-center">
      <img
        class="avatar__alicia-pq"
        v-if="flagImg == 1"
        src="../../assets/img/nueva_alicia_pages_all.png"
      />
      <!--<img class="avatar__alicia__mobile-pq" src="../../assets/img/alicia_3.jpg" />-->
    </div>

    <b-modal
      id="modal-lg"
      size="lg"
      hide-footer
      no-close-on-backdrop
      hide-header-close
      no-close-on-esc
    >
      <template #modal-title> ACTUALIZA TU DOCUMENTO DE INDENTIDAD :</template>
      <div class="form-group col-md-12">
        <label for="">Documento de indetidad</label>
        <p
          v-if="isDocumentoUpdate"
          style="color: red; font-size: 0.8em; margin: 0 0 5px 0; padding: 0"
        >
          (*)El campo documento de identidad esta vacio.
        </p>
        <input
        v-if="tipoDocumento.toLowerCase() == 'dni'"
          type="text"
          placeholder="Documento de identidad"
          class= "form-control"
          maxlength="8"
          v-model="dni_update"
          @keypress="validateNumber($event)"
        />
        <input
          v-else
          type="text"
          placeholder="Documento de identidad"
          class= "form-control"
          maxlength="11"
          v-model="dni_update"
          @keypress="validateNumber($event)"
        />
      </div>
      <div class="form-group col-md-7">
        <button
          type="button"
          class="btn-el-comercio btn__actualizar"
          @click="actualizarDni()"
        >
          Actualizar
        </button>
      </div>
      <span style="padding-left: 1.5em">¡Espero haberte ayudado!</span>
      <br />
      <b-button
        class="mt-3"
        block
        @click="$bvModal.hide('modal-lg')"
        style="padding: 7px; border-radius: 3px"
        >Cerrar</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Cards",
  data: function () {
    return {
      suscripciones: [],

      suscriptionSelected: {},
      arrOpcion: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "Si actualizar mi correo de acceso" },
        { id: "2", name: "No conservar mi correo de acceso" },
      ],
      show: true,
      firstName: localStorage.getItem("name").split(" ")[0].charAt(0).toUpperCase() + localStorage.getItem("name").split(" ")[0].toLowerCase().slice(1), 
      isLoading: false,
      positionId: "",
      state: "-1",
      categoria: "",
      diasEntrega: "",
      siguientePago: "",
      periodoSuscripcion: "",
      dni: "",
      tipoDocumento: "",
      dni_update: "",
      isDocumentoUpdate: false,
      fullPage: true,
      flagImg: 1,
    };
  },
  components: {
    Loading,
  },
  methods: {
    setValModal() {
      let vm = this;
      vm.dni_update = vm.dni;
      if (vm.dni_update != "") {
        vm.isDocumentoUpdate = false;
      } else {
        vm.isDocumentoUpdate = true;
      }
    },
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    listSubscriptions() {
      let vm = this;
      vm.isLoading = true;
      vm.suscriptionSelected = {};

      let data = {
        document_number: localStorage.getItem("numeroDocumento"),
        document_type: localStorage.getItem("tipoDocumento"),
        optionQuery: "2",
        queryType: "1",
        optionProcess: "1",
        channel: "7",
      };
      //console.log("Send Data =>", data);
      axios
        .post(`${process.env.VUE_APP_ROOT_API}/listSuscripChatbot`, data)
        .then(function (res) {
          vm.isLoading = false;

          if (res.data.data.code == 2) {
            vm.isLoading = false;
            vm.suscripciones = [res.data.data];
            vm.emailVerificado = "";

            vm.$fire({
              title: "Felicidades",
              text: `${res.data.data.message}`,
              type: "success",
              timer: 3000,
            }).then((r) => {});
          } else {
            vm.isLoading = false;
            vm.$fire({
              title: "Seguridad",
              text: "Usted no tiene suscripciones",
              type: "info",
              timer: 3000,
            }).then((r) => {});
            vm.suscripciones = [];
          }
        })
        .catch((err) => {
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: "Ocurrio un error en el servidor",
            type: "error",
            timer: 3000,
          }).then((r) => {});
        });
    },
    async changeSuscription(event) {
      let vm = this;
      vm.positionId = event.target.value;
      vm.isLoading = true;
      vm.suscriptionSelected = {};

      if (vm.positionId == "") {
        vm.isLoading = false;
      } else {
        vm.isLoading = false;
        let data = {
          positionId: vm.positionId,
          document_number: localStorage.getItem("numeroDocumento"),
          document_type: localStorage.getItem("tipoDocumento"),
        };

        const response = await axios
          .post(`${process.env.VUE_APP_ROOT_API}/actualizardatos-list`, data)
          .then((res) => {
            vm.isLoading = false;
            return res.data.data;
          })
          .catch((err) => {
            vm.isLoading = false;
            return err;
          });

        //console.log("response", response);

        if (response.code == 1) {
          vm.suscriptionSelected = response;
          vm.isLoading = false;
          vm.$fire({
            title: "Importante",
            text: `Tu suscripción ${response.dataSubscription.product} está registrada con el ${response.dataSubscription.documentType} ${response.dataSubscription.documentNumber}.\n\nRecuerda que el cambio se puede realizar sobre el mismo tipo de documento. `,
            type: "info",
            timer: 4000,
          }).then((r) => {});
          vm.state = "1";
          vm.categoria = response.dataSubscription.category;
          vm.diasEntrega = response.dataSubscription.deliveryDays;
          vm.siguientePago = response.dataSubscription.nextPaymentDate;
          vm.periodoSuscripcion = response.dataSubscription.periodicity;
          vm.tipoDocumento = response.dataSubscription.documentType;
          vm.dni = response.dataSubscription.documentNumber;
        } else {
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: `Algo salio mal, por favor intente nuevamente`,
            type: "error",
            timer: 4000,
          }).then((r) => {});
          vm.state = "0";
        }
      }
    },
    async actualizarDni() {
      let vm = this;

      if (vm.dni_update.length < 8) {
        vm.isDocumentoUpdate = true;
        vm.isLoading = false;
      } else {
        vm.isDocumentoUpdate = false;
        vm.isLoading = false;

        let data = {
          document_number: localStorage.getItem("numeroDocumento"),
          document_type: localStorage.getItem("tipoDocumento"),
          positionId: vm.positionId,
          documentNumber: vm.dni_update,
          platformOrigin: "Landing",
        };

        const response = await axios
          .post(`${process.env.VUE_APP_ROOT_API}/actualizardatos-dniruc`, data)
          .then((res) => {
            vm.isLoading = false;
            return res.data.data;
          })
          .catch((err) => {
            vm.isLoading = false;
            return err;
          });

        //console.log("response final", response);

        if (response.code == 1) {
          //registra cambio de dni
          vm.isLoading = false;
          vm.$fire({
            title: "¡Gracias por usar este canal!",
            text: `El cambio se hará efectivo dentro de las 24 horas.\n\nTe llegará un correo al mail ${response.email} para confirmar el cambio solicitado.`,
            type: "success",
            timer: 4000,
          }).then((r) => {});
          vm.$bvModal.hide("modal-lg");
        } else if (response.code == 2) {
          //no puede cambiar el dni ya tiene uns solicitud pendiente
          vm.isLoading = false;
          vm.$fire({
            title: "Ops",
            text: `${vm.firstName}, cuentas con una solicitud en proceso.`,
            type: "info",
            timer: 4000,
          }).then((r) => {});
          vm.$bvModal.hide("modal-lg");
        }else if(response.code == 3){
          //no puede cambiar a otro tipo de documento
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: `Tu suscripción está registrada como persona ${response.personType}. Por lo que no es posible cambiar tu suscripción a otro tipo de documento. Te recomendarnos contactarnos vía Whatsapp al 955 559 683 o a través de clubelcomercio.pe`,
            type: "error",
            timer: 4000,
          }).then((r) => {});
          vm.$bvModal.hide("modal-lg");
        }else{
          //error
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: `Algo salio mal, por favor intente nuevamente`,
            type: "error",
            timer: 2000,
          }).then((r) => {});
          vm.$bvModal.hide("modal-lg");
        }
      }
    },
    showImg() {
      this.flagImg = 1;
    },
    hideImg() {
      this.flagImg = 0;
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    vm.listSubscriptions();
  },
};
</script>
